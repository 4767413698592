import { useEffect, useState } from "react";
import * as P from "./style";
import { FaArrowLeft } from "react-icons/fa";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { CommonButton } from "../../common-styles";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Redux/store";
import { useNavigate } from "react-router-dom";
import CommonLoader from "../../Components/CommonLoader";
import {
  fetchMemberShipDetails,
  updateMemberShipDetails,
  updatePricing,
} from "../../Redux/slices/settingSlice";

const MembershipPricing = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { loading: userLoading, id: userId } = useSelector(
    (state: RootState) => state.user
  );
  const settings = useSelector((state: RootState) => state.setting);
  const pricingSettings = settings.memberDetails;

  const [range] = useState({
    monthly: { minPrice: 30, maxPrice: 100 },
    message: { minPrice: 30, maxPrice: 500 },
  });

  const applyChanges = () => {
    const payload = {
      monthlyPrice: pricingSettings.monthlyPrice.toString(),
      messagePrice: pricingSettings.messagePrice.toString(),
    };

    dispatch(updateMemberShipDetails(payload)).then(() => {
      navigate("/activity");
    });
  };

  const handlePriceChange = (
    pricingKey: "monthlyPrice" | "messagePrice",
    value: any
  ) => {
    if (typeof value !== "number") return;
    dispatch(
      updatePricing({
        paramKey: "memberDetails",
        pricingKey,
        priceValue: value.toString(),
      })
    );
  };

  useEffect(() => {
    if (userId) dispatch(fetchMemberShipDetails(userId));
  }, [dispatch, userId]);

  if (!userLoading && !userId) return <CommonLoader message="loading..." />;

  return (
    <P.Pricing className="text-white">
      <h1 className="text-white font-noto flex items-center gap-5 md:gap-10">
        <span onClick={() => navigate("/account")}>
          <FaArrowLeft
            className="cursor-pointer"
            onClick={() => navigate("/account")}
            size={30}
          />
        </span>
        <span className="font-semibold">Membership</span>
      </h1>

      <div>
        <p className="text-white uppercase md:text-lg">
          Set monthly membership price
        </p>
        <div className="mt-12">
          <InputRange
            step={10}
            allowSameValues
            minValue={range.monthly.minPrice}
            maxValue={range.monthly.maxPrice}
            value={parseInt(settings.memberDetails.monthlyPrice)}
            onChange={(value) => handlePriceChange("monthlyPrice", value)}
          />
        </div>
      </div>

      {/* Direct Message Pricing */}
      <div className="mt-[5rem]">
        <p className="text-white uppercase md:text-lg">
          Set Direct Message Price
        </p>
        <div className="mt-12">
          <InputRange
            step={20}
            allowSameValues
            minValue={range.message.minPrice}
            maxValue={range.message.maxPrice}
            value={parseInt(settings.memberDetails.messagePrice)}
            onChange={(value) => handlePriceChange("messagePrice", value)}
          />
        </div>
      </div>

      <div className="mt-[5rem] md:mt-[8rem]">
        <CommonButton variant="light" onClick={applyChanges}>
          Apply
        </CommonButton>
      </div>

      <div className="mt-10 md:mt-14 text-white text-center">
        <p className="text-[15px] font-medium">Find your ideal pricing</p>
        <p className="text-xs mt-3">
          It's better to start with lower pricing first and then gradually
          increase it as you gain members to maximize revenue
        </p>
      </div>
    </P.Pricing>
  );
};

export default MembershipPricing;
