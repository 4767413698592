import React from "react";
import { Modal } from "antd";

interface Props {
  onHide: () => void;
  show: boolean;
  setConfirmDelete: (isConfirm: boolean) => void;
}

const DeletePostModal: React.FC<Props> = ({ onHide, show, setConfirmDelete }) => {
  return (
    <>
      {/* Delete Post Modal */}
      <Modal
        onCancel={onHide}
        footer={false}
        closeIcon={false}
        open={show}
        width={600}
        rootClassName="fanxo-modal"
        destroyOnClose
      >
        <div className="bg-black text-center">
          <h5 className="text-xl text-white">Delete Intro Post?</h5>
          <p className="text-[#979797] text-lg mt-4 mb-6">
            Are you sure?
          </p>
        </div>

        <div>
          <button className="bg-transparent w-full py-3 text-[#ED4956] text-lg border-t border-[#ffffff80]" onClick={() => setConfirmDelete(true)}>
            Delete
          </button>
          <button className="bg-transparent w-full py-3 text-lg border-t border-[#ffffff80]" onClick={() => setConfirmDelete(false)}>Cancel</button>
        </div>
      </Modal>
    </>
  );
};

export default DeletePostModal;
