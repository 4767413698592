import React from "react";
import "./App.css";
import AppRoutes from "./Routes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";
import { RootState } from "./Redux/store";
import PublicRoutes from "./Routes/publicRoute";

const App: React.FC = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  return (
    <div className="App">
      {isAuthenticated ? <AppRoutes /> : <PublicRoutes />}
    </div>
  );
};

export default App;
