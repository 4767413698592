import * as C from "./styled";
import Logo from "../../Assets/Images/logo-colored.svg";
import { ColoredBtn } from "../../common-styles";
import { Link } from "react-router-dom";

const Thankyou = () => {
  return (
    <C.ThankyouWrapper>
      <C.Content className="min-h-screen flex items-center justify-center flex-col">
        <img src={Logo} alt="fanxo logo" className="app-logo mx-auto" />
        <h1 className="text-white text-center font-roboto text-2xl mt-5">
          Thanks For Applying
        </h1>
        <p className="text-white text-center font-roboto leading-7 pb-8 pt-4">
          Your application is under process and your account will be activated by the fanxo talent team within 4 hours after verification. You will also receive an activation mail after which you can log in and start your premium account on fanxo!
        </p>
        <div className="text-center">
          <Link to={"/"}>
            <ColoredBtn className="!px-14 text-white">Return Home</ColoredBtn>
          </Link>
        </div>
      </C.Content>
    </C.ThankyouWrapper>
  );
};

export default Thankyou;
