import { useEffect } from "react";
import { FaArrowRight } from "react-icons/fa6";
import * as A from "./style";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Redux/store";
import { fetchMemberShipDetails } from "../../Redux/slices/settingSlice";
import CommonLoader from "../../Components/CommonLoader";

const Activity = () => {
  const dispatch: AppDispatch = useDispatch();
  const settings = useSelector((state: RootState) => state.setting);
  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (user.id) {
      dispatch(fetchMemberShipDetails(user.id));
    }
  }, [dispatch, user.id]);

  if (settings.loading)
    return <A.Activity>
      <h1 className="text-white text-center font-noto font-semibold">
        Activity
      </h1>
      <CommonLoader message="fetching activity details..." />
    </A.Activity>

  return (
    <A.Activity>
      <h1 className="text-white text-center font-noto font-semibold">
        Activity
      </h1>

      {/* Membership */}
      <div className="membership">
        <div>
          <h3 className="text-white mb-5 text-2xl md:text-3xl font-medium">
            Membership
          </h3>
          <h3 className="flex items-center justify-between text-white md:text-xl">
            <span>Total Membership</span>
            <span>{settings?.memberIncomeStatistic?.totalSubscribers ?? 0}</span>
          </h3>

          <Link
            to="/membership/pricing"
            className="text-white text-md flex gap-2 md:gap-3 items-center mt-3"
          >
            <span className="text-sm">Change Membership Settings</span>{" "}
            <FaArrowRight />
          </Link>
        </div>
      </div>

      {/* Earnings */}
      <div className="earnings mt-[4rem]">
        <div>
          <h3 className="text-white mb-5 text-2xl md:text-3xl font-medium">
            Earnings
          </h3>
          <div className="flex flex-col gap-2">
            <h3 className="flex items-center justify-between text-white md:text-xl">
              <span>Total Earnings (Past 30 Days)</span>
              <span>
                &#8377;
                {(() => {
                  const totalMessageOrdersLast30Days =
                    Number(
                      settings?.memberIncomeStatistic?.totalMessageOrdersLast30Days
                    ) || 0;
                  const totalSubscribeOrdersLast30Days =
                    Number(
                      settings?.memberIncomeStatistic
                        ?.totalSubscribeOrdersLast30Days
                    ) || 0;

                  const totalEarnings =
                  (totalMessageOrdersLast30Days +
                    totalSubscribeOrdersLast30Days) / 100;

                  return totalEarnings.toFixed(2);
                })()}
              </span>
            </h3>
            <h3 className="flex items-center justify-between text-white md:text-xl">
              <span>From Membership</span>
              <span>
                &#8377;
                {settings.memberIncomeStatistic?.totalSubscribeOrders
                  ? (settings.memberIncomeStatistic.totalSubscribeOrders / 100).toFixed(2)
                  : 0}
              </span>
            </h3>
            <h3 className="flex items-center justify-between text-white md:text-xl">
              <span>From Direct Messages</span>
              <span>
                &#8377;
                {settings?.memberIncomeStatistic?.totalMessageOrders
                  ? (settings.memberIncomeStatistic.totalMessageOrders / 100).toFixed(2)
                  : 0}
              </span>
            </h3>
          </div>
        </div>
      </div>

      {/* Post Interaction */}
      <div className="earnings mt-[4rem]">
        <div>
          <h3 className="text-white mb-5 text-2xl md:text-3xl font-medium">
            Post Interaction
          </h3>
          <div className="flex flex-col gap-2">
            <h3 className="flex items-center justify-between text-white md:text-xl">
              <span>Post Total</span>
              <span>{settings?.memberStatistic?.numberOfPosts ?? 0}</span>
            </h3>
            <h3 className="flex items-center justify-between text-white md:text-xl">
              <span>Likes</span>
              <span>{settings?.memberStatistic?.totalLikes ?? 0}</span>
            </h3>
            <h3 className="flex items-center justify-between text-white md:text-xl">
              <span>Comments</span>
              <span>{settings?.memberStatistic?.totalComments ?? 0}</span>
            </h3>
          </div>
        </div>
      </div>
    </A.Activity>
  );
};

export default Activity;
