import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as interFace from "../../Config/interface.config";
import APIRequest from "../../API";
import * as APIPATHS from "../../API/path";
import toast from "react-hot-toast";

interface AddComment {
  post_id: string;
  comment: interFace.SingleComment;
}

const initialState: interFace.PostDetails = {
  posts: [],
  feed: {
    limit: 10,
    page: 1,
    results: [],
    totalPages: 1,
    totalResults: 1,
  },
  loading: false,
};

export const createNewPost = createAsyncThunk<any, interFace.Post>(
  "post/create-new-post",
  async (postData, { rejectWithValue, dispatch }) => {
    try {
      toast.loading("Creating new post...");
      const response: any = await APIRequest(APIPATHS.createPost, postData);
      toast.remove();
      if (response.error) throw new Error(response.error.message);
      toast.success("Post created successfully", { duration: 3000 });
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ reason: error.message ?? error.response.data });
    }
  }
);

export const updatePost = createAsyncThunk<any, interFace.EditPostPayload>(
  "post/update-post",
  async (postData, { rejectWithValue, dispatch }) => {
    try {
      if (!postData._id) return toast.error("Post ID is in-valid.");

      toast.loading("Updating post...");
      let path = { ...APIPATHS.updatePost };

      path.url = path.url.replace("{{postId}}", postData._id);
      delete postData["_id"];
      delete postData["files"];
      const response: any = await APIRequest(path, postData);
      toast.remove();

      if (response.error) throw new Error(response.error.message);
      toast.success("Post updated successfully", { duration: 3000 });
      // dispatch(feedHandler({ limit: 1000, page: 1 }))
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ reason: error.message ?? error.response.data });
    }
  }
);

export const feedHandler = createAsyncThunk<
  any,
  { limit: number; page: number; creator?: string; isIntro?: boolean }
>("post/feed", async (data, { rejectWithValue, getState }) => {
  try {
    const response: any = await APIRequest(APIPATHS.myFeed, {}, { ...data });

    if (response.error) {
      toast.error(response.error);
      return rejectWithValue(response.error);
    }
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message ?? error.response.data);
  }
});

export const postDelete = createAsyncThunk<any, { postId: string }>(
  "post/delete/feed",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      toast.loading("Deleting your post...");

      let apiConfig = { ...APIPATHS.postDelete };
      apiConfig.url = apiConfig.url.replace("{{postId}}", data.postId);

      const response: any = await APIRequest(apiConfig);
      toast.remove();

      dispatch(feedHandler({ limit: 1000, page: 1 }));
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message ?? error.response.data);
    }
  }
);

const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    updateFeedPostComment(state, { payload }: PayloadAction<AddComment>) {
      state.feed.results = state.feed.results.map((post) => {
        if (post._id === payload.post_id) post.comments.push(payload.comment);
        return post;
      });
    },

    updatePostLike(
      state,
      { payload }: PayloadAction<interFace.LikePostResponse>
    ) {
      state.feed.results = state.feed.results.map((post) => {
        const is_exists = post.likeCount.find(
          (like) => like._id === payload._id
        );
        if (is_exists) return post;

        if (post._id === payload._id) post.likeCount.push(payload);
        return post;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewPost.pending, (state) => {
        state.loading = true;
      })
      .addCase(createNewPost.fulfilled, (state, action) => {
        state.posts.unshift(action.payload);
        state.feed.results.unshift(action.payload);
        state.loading = false;
      })
      .addCase(createNewPost.rejected, (state, action: any) => {
        state.loading = false;
        toast.error(action.payload.reason);
      });

    builder
      .addCase(feedHandler.pending, (state) => {
        state.loading = true;
      })
      .addCase(feedHandler.fulfilled, (state, action) => {
        if (Array.isArray(action.payload.results))
          action.payload.results = action.payload.results.reverse();
        else action.payload.results = []

        state.feed = action.payload;
        state.loading = false;
      })
      .addCase(feedHandler.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { updateFeedPostComment, updatePostLike } = postSlice.actions;
export default postSlice.reducer;
