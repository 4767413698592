import { useState } from 'react'
import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Modal, Space } from 'antd';
import { FeedPosts } from '../../Config/interface.config';
import { postDelete } from '../../Redux/slices/postSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../Redux/store';
import CreatePostModal from '../../Modals/CreatePostModal';


interface Props {
  post?: FeedPosts;
}

const PostAction = ({ post }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch()
  const [createPostModal, setCreatePostModal] = useState(false);

  const toggleModal = () => setOpen(!open)

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div onClick={() => handleEditPost()}>
          <p className='text-white mb-0'>Edit Post</p>
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div onClick={toggleModal}>
          <p className='text-white mb-0'>Delete Post</p>
        </div>
      ),
    },
  ];

  const deletePosthandler = async () => {
    if (typeof post?._id !== 'string') return ''
    await dispatch(postDelete({ postId: post?._id }))
    toggleModal();
  }

  const toggleCreatePostModal = () => setCreatePostModal(!createPostModal);


  const handleEditPost = () => {
    if (typeof post?._id !== 'string' || typeof post.text !== 'string') return;
    toggleCreatePostModal();
  }

  return (
    <>
      <Dropdown menu={{ items }} placement='bottomRight' rootClassName='fanxo-dropdown'>
        <Space>
          <DownOutlined className='text-white' />
        </Space>
      </Dropdown>

      {/* Delete Post Modal */}
      <Modal
        onCancel={toggleModal}
        footer={false}
        closeIcon={false}
        open={open}
        width={600}
        rootClassName='fanxo-modal'
        destroyOnClose
      >
        <div className='bg-black text-center'>
          <h5 className='text-xl text-white'>Delete Post ?</h5>
          <p className='text-[#979797] text-lg mt-4 mb-6'>If you leave, your edits won’t be saved</p>
        </div>

        <div>
          <button className='bg-transparent w-full py-3 text-[#ED4956] text-lg border-b border-[#ffffff80] bg-white'
            onClick={() => deletePosthandler()}>Delete</button>
          <button className='bg-transparent w-full py-3 text-lg'>Cancel</button>
        </div>
      </Modal>

      {/* Edit Post Modal */}
      <CreatePostModal
        onHide={() => toggleCreatePostModal()}
        show={createPostModal}
        key={"edit-post-modal"}
        editPost={post}
      />

    </>

  )
}

export default PostAction
