import React, { useState } from "react";
import * as S from "./styles";
import * as C from "../../common-styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TbEyeClosed } from "react-icons/tb"; // Assuming this is the correct import for the icon
import ColoredLogo from "../../Assets/Images/logo-colored.svg";
import GoogleIcon from "../../Assets/Images/google-icon.svg";
import { Formik, Form } from "formik";
import * as validation from "../../Config/validation.config";
import * as config from "../../Config/common.config";
import * as APIPATHS from "../../API/path";
import APIRequest from "../../API";
import toast from "react-hot-toast";
import { AiOutlineEye } from "react-icons/ai";
import * as interFace from "../../Config/interface.config";
import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import { RoleTypes } from "../../Constant/enums/roleTypes";
import { FaChevronRight } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { login } from "../../Redux/slices/authSlice";

const SignUp: React.FC = () => {
  const { role } = useParams<{ role: string }>(); // Specify the type for useParams
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData] = useState<interFace.SignUpInterface>({
    email: "",
    fullname: "",
    password: "",
    role: "",
  });

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const onSignUp = async (values: interFace.SignUpInterface) => {
    // Your submission logic goes here
    values.role = role
      ? process.env.REACT_APP_CREATOR_ROLE_ID
      : process.env.REACT_APP_USER_ROLE_ID;
    const promise = APIRequest(APIPATHS.register, values);

    // creating new user
    toast.promise(promise, {
      loading: "Signin you up...",
      success: (data: any) => {
        if (data.error) throw new Error(data.error.message);
        const { access, refresh }: interFace.AuthData = data.data.tokens;
        config.updateToken(access.token, refresh.token);
        dispatch(login());
        if (role) {
          navigate("/creator-details");
          return "Account created successfully.";
        }

        navigate("/home");
        return "Account created successfully.";
      },
      error: (err) => {
        return err.message;
      },
    });
  };

  const registerWithGoogle = (credentialResponse: any) => {
    const { email, family_name, given_name }: any =
      credentialResponse.data.user;
    const payload: interFace.SignUpInterface = {
      email,
      fullname: given_name + " " + family_name,
      password: config.generatePassword(12),
      role,
    };
    onSignUp(payload);
  };

  const authenticateUsingGoogle = useGoogleLogin({
    onSuccess: async ({ access_token }: TokenResponse) => {
      const promise = APIRequest(APIPATHS.authWithGoogle, {
        token: access_token,
      });
      // auth user with google
      toast.promise(promise, {
        loading: "Authenticating...",
        success: (data: any) => {
          if (data.error) {
            toast.error(data.error.message); // Show error message via toast
            return "Failed to authenticate!"; // Return a failure message
          }
          registerWithGoogle(data);
          return "Authentication successful!"; // Return a success message
        },
        error: (err: any) => {
          return err.message; // Ensure this returns a string
        },
      });
    },
  });

  const toggleShowPassword = () => setShowPassword((prev) => !prev);

  return (
    <S.SignUpWrapper>
      <S.SignUpContainer>
        <S.ColoredLogo src={ColoredLogo} />
        <S.SignUpContent>
          <h1 className="font-roboto font-reg capitalize">
            {role === RoleTypes.CREATOR ? "Creator Application" : "Sign Up"}
          </h1>

          <Formik
            initialValues={formData}
            onSubmit={onSignUp}
            validationSchema={validation.signUpValidation}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
              resetForm,
            }) => (
              <Form>
                <div className="login-form">
                  <div>
                    <C.CommonInput
                      placeholder="Full name"
                      name="fullname"
                      value={values.fullname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <p className="text-white mt-0.5">
                      {errors.fullname && touched.fullname && errors.fullname}
                    </p>
                  </div>
                  <div>
                    <C.CommonInput
                      placeholder="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <p className="text-white mt-0.5">
                      {errors.email && touched.email && errors.email}
                    </p>
                  </div>
                  <div>
                    <C.IconInputWrapper>
                      <C.CommonInput
                        placeholder="Password"
                        name="password"
                        type={!showPassword ? "password" : "text"}
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {showPassword ? (
                        <AiOutlineEye
                          onClick={toggleShowPassword}
                          className="input-icon"
                          size={18}
                        />
                      ) : (
                        <TbEyeClosed
                          onClick={toggleShowPassword}
                          className="input-icon"
                          size={18}
                        />
                      )}
                    </C.IconInputWrapper>
                    <p className="text-white mt-0.5">
                      {errors.password && touched.password && errors.password}
                    </p>
                  </div>

                  <C.CommonButton
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Continue
                  </C.CommonButton>

                  <p className="text-white text-center">
                    By signing up you agree to our terms and{" "}
                    <Link
                      to="/privacy-policy"
                      className="underline-offset-2 underline"
                    >
                      privacy policy
                    </Link>
                  </p>

                  <div className="or-title">
                    <span>Or</span>
                  </div>

                  <div className="relative">
                    <C.CommonIonButton
                      onClick={() => authenticateUsingGoogle()}
                      type="button"
                      className="border border-[#BABABA]"
                    >
                      <img alt="fanxo-logo" width={20} src={GoogleIcon} />
                      <span>Signup With Google</span>
                    </C.CommonIonButton>

                    {role !== RoleTypes.CREATOR && (
                      <div className="mt-5">
                        <Link
                          onClick={() => resetForm()}
                          className="text-center text-white flex items-center justify-center gap-3"
                          to="/signup/creator"
                        >
                          <span>Sign up as creator</span>
                          <FaChevronRight size={18} color="white" />
                        </Link>
                      </div>
                    )}

                    {role === RoleTypes.CREATOR && (
                      <div className="mt-5">
                        <Link
                          onClick={() => resetForm()}
                          className="text-center text-white flex items-center justify-center gap-3"
                          to="/signup"
                        >
                          <span>Sign up as fan</span>
                          <FaChevronRight size={18} color="white" />
                        </Link>
                      </div>
                    )}

                    <div className="mt-5">
                      <Link
                        onClick={() => resetForm()}
                        className="text-center text-white flex items-center justify-center gap-3"
                        to="/login"
                      >
                        <span>Log in</span>
                        <FaChevronRight size={18} color="white" />
                      </Link>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </S.SignUpContent>
      </S.SignUpContainer>
    </S.SignUpWrapper>
  );
};

export default SignUp;
