import React, { useEffect, useMemo } from "react";
import * as M from "./style";
import moment from "moment";
import userImg from "../../Assets/Images/user-profile-img.png";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { Message } from "../../Config/interface.config";
import { messageScrollHandler } from "../../Utils";

interface Prop {
  activeChat: string;
}

const Messages: React.FC<Prop> = ({ activeChat }) => {
  const { messages: allMessages } = useSelector(
    (state: RootState) => state.message
  );
  const { id } = useSelector((state: RootState) => state.user);

  const groupedMessages = useMemo(() => {
    return allMessages
      .filter((msg) => msg.subscriberId === activeChat && msg.createdAt) // Ensure createdAt exists
      .reduce((acc: Record<string, Message[]>, msg) => {
        const date = msg.createdAt
          ? new Date(msg.createdAt).toLocaleDateString("en-GB")
          : "";
        if (!acc[date]) acc[date] = [];
        acc[date].push(msg);
        return acc;
      }, {});
  }, [allMessages, activeChat]);

  const getMessageDate = (key: string) => {
    const today = moment().format("D-MM-YYYY");
    const yesterday = moment().subtract(1, "days").format("D-MM-YYYY");
    if (key === today) return "Today";
    if (key === yesterday) return "Yesterday";
    return moment(key, "D-MM-YYYY").format("ddd, D MMM");
  };

  useEffect(() => {
    messageScrollHandler();
  }, [groupedMessages]);

  return (
    <M.MessagesWrapper>
      {Object.entries(groupedMessages).map(([date, messages]) => (
        <M.MessageBox key={`message-date-${date}`} className="text-white">
          {messages.length > 0 && (
            <M.MessageDate>{getMessageDate(date)}</M.MessageDate>
          )}
          <M.Messages>
            {messages.map((mess, index) => (
              <M.TextMessageWrapper
                key={`${date}-message-${index}`}
                remote={(mess.from._id !== id).toString()}
              >
                <M.ProfileImg
                  src={
                    mess.from._id !== id
                      ? mess.from?.image?.fileURL || userImg // If receiver has image, use it; otherwise use default
                      : mess.to?.image?.fileURL || userImg // If sender has image, use it; otherwise use default
                  }
                  alt="user"
                  crossOrigin="anonymous"
                />
                <M.Message remote={(mess.from._id !== id).toString()}>
                  <M.Text>{mess.text}</M.Text>
                  <M.Time remote={(mess.from._id !== id).toString()}>
                    {moment(mess.createdAt).format("HH:mm")}
                  </M.Time>
                </M.Message>
              </M.TextMessageWrapper>
            ))}
          </M.Messages>
        </M.MessageBox>
      ))}
    </M.MessagesWrapper>
  );
};

export default Messages;
