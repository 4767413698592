import styled from "styled-components";
import { CommonButton, CommonInput } from '../../common-styles'

interface UserListProp {
    showUserList: boolean;
}

interface MessageShaderProp {
    isVisible: boolean;
}

export const MessageWrapper = styled.div`
    padding: 1rem;
    padding-left: 0;
    background-color: #000000;
    padding-bottom: 0;

    @media screen and (max-width: 800px){
        padding: 1rem;
    }
`;

export const MessageHeading = styled.h1`
    display: flex;
    align-items: center;
    gap: 1rem;

    span{
        font-size: 1.2rem;
        font-weight: 400;
    }

    .menu-icon{
        display: none;
        cursor: pointer;
        @media screen and (max-width: 800px){
            display: block;
        }
    }
`

export const MessageContainer = styled.div`
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    height: calc(100vh - 58px);
    display: flex;

    @media screen and (max-width: 768px) {
        height: calc(100vh - 110px);
    }
`

export const UserListWrallper = styled.div<UserListProp>`
    width: 320px;
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 0 1rem 0;
    transition: 0.3s;

  /* Style the scrollbar and its track */
  &::-webkit-scrollbar {
    width: 8px; /* width of the scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: #000000; /* color of the track */
    border: 1px solid white;
  }

  /* Style the scrollbar thumb */
  &::-webkit-scrollbar-thumb {
    background: #ffffff; /* color of the thumb */
    border-radius: 4px; /* roundness of the thumb */
  }

  /* Change the appearance of the scrollbar on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #e5e5e5;
  }

    @media screen and (max-width: 800px) {
        position: fixed;
        width: 70%;
        z-index: 20;
        background-color: #000000;
        height: 100vh;
        top: 0px;
        left: 0;
        transform: translateX(${({ showUserList }) => showUserList ? '0px' : '-100%'});
    }
`


export const MessageShader = styled.div<MessageShaderProp>`
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
    width: 30%;
    height: 100vh;
    background-color: #000;
    transition: 0.3s;
    display: none;

    @media screen and (max-width: 800px) {
        display: block;
        opacity: ${({ isVisible }) => isVisible ? 0.5 : 0};
        pointer-events: ${({ isVisible }) => isVisible ? 'all' : 'none'};
    }
`;

export const MessageSection = styled.div`
    flex: 8;
    position: relative;
    display:flex;
    flex-direction: column;
    max-width: 700px;
    margin: 0 auto;
`

export const SubscribeButton = styled.button`
   background: #000000;
   padding: .5rem 2rem;
   color: #ffffff;
   border-radius: 50px;
   border: 1px solid white;
   font-size: 15px;
`;

export const SubscribeBox = styled.div`
    height: 60px;
    width: calc(100% - (2rem + 10px));
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    margin-left: 1rem;
    margin-bottom: .5rem;
`

export const MessagesWrapper = styled.div`
    flex: 1;
    color: white;
    overflow: auto;
    margin-bottom: 0.5rem;
`

export const MessageHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;

    @media screen and (max-width: 768px){
      button {
        padding: 0.5rem;
          span{
              display: none;
            }
        }
    }
`;

export const NewMessageButton = styled(CommonButton)`
   padding: 0.5rem 1rem;
   color: rgb(255 255 255 / 70%);
   background: #000000;
   border-radius: 50px;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 0.5rem;
   border: 1px solid rgba(255, 255, 255, .7);

   &:hover{
    color: white;
    background-color: #000000;
   }
`

export const MessageActionWrapper = styled.form`
    padding: 0 0.8rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    background-color: white;
    border-radius: 5px;
    margin-left: 1rem;
`

export const NewMessage = styled(CommonInput)`
    flex: 1;
    background-color: transparent;
    height: unset;
    padding: .8rem 0;
    font-size: 16px;
    border-radius: 7px;
    border: none;
    color: #767676;
    font-family: 'Noto Sans', sans-serif;
    opacity: 1;
    &::placeholder{
        color: #767676;
    }
`;

export const MessageActionBtn = styled.button<{ bgcolor?: string, color?: string }>`
    background-color: ${(props) => props.bgcolor ?? '#fff'};
    color: ${(props) => props.color ?? '#2F80ED'};
    width: 40px;
    height: 40px;
    aspect-ratio: 1/1;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    .button-icon{
        font-size: 1.6rem;
    }
`

export const MessageBtnWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: .5rem;
`

export const UserListTools = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    position: sticky;
    top: 0;
    background-color: #000000;
    p, svg{
        color: rgb(255, 255, 255, 0.7);
    }

    svg{
        cursor: pointer;
    }

    @media screen and (max-width: 800px){
        padding: 1rem;
    }
`

export const SeletedChatUser = styled.h1`
    color: white;
    font-size: 1.4rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    margin-left: 1.5rem;
    padding-bottom: .2rem;
`

export const PaymentModalWrapper = styled.div`
    color: white;
`

export const UserImage = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #ffffff;
    object-fit: cover;
`