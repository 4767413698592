import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Redux/store";
import userImg from "../../Assets/Images/user-profile-img.png";
import { MdVerified } from "react-icons/md";
import * as S from "./styled";
import { CommonButton, CommonInput } from "../../common-styles";
import { togglePaymentModal } from "../../Redux/slices/messageSlice";
import { Link } from "react-router-dom";

interface Prop {
  onSend: () => void;
  messageCharge: string | undefined;
}

const ChatPaymentModal = ({ onSend, messageCharge }: Prop) => {
  const dispatch: AppDispatch = useDispatch();
  const { subscribers, activeChat, paymentModal } = useSelector(
    (state: RootState) => state.message
  );
  const selectedUser = subscribers.find((subs) => subs._id === activeChat);
  const settings = useSelector((state: RootState) => state.setting);

  return (
    <Modal
      footer={false}
      className="create-post-modal"
      closeIcon={false}
      width={600}
      open={paymentModal}
      onCancel={() => dispatch(togglePaymentModal())}
      destroyOnClose
    >
      <div className=" w-full rounded-[15px] bg-gradient-to-b from-[#146BC3] via-[#B77BC6] to-[#F48E1D] p-0.5 overflow-hidden">
        <S.PaymentModalWrapper className="h-full w-full bg-black rounded-[15px]  overflow-hidden p-5 px-10">
          <div className="flex gap-4">
            <img
              src={selectedUser?.creator?.image?.fileURL ?? userImg}
              className="w-[60px] h-[60px] object-cover rounded-full"
              alt="user"
              crossOrigin="anonymous"
            />
            <div>
              <span className="flex gap-3 items-center">
                <h3 className="text-2xl font-light">
                  {selectedUser?.creator?.fullname}
                </h3>
                <MdVerified fill="white" size={25} />
              </span>
              <p className="text-white/70 text-xl">
                @{selectedUser?.creator?.username}
              </p>
            </div>
          </div>

          <div className="mt-8">
            <h3 className="text-xl">Pay for Message</h3>
            {settings.memberDetails && (
              <div className="mt-5">
                <label className="text-white/70 mb-2">Total Amount</label>
                <CommonInput
                  className="!text-lg"
                  type="text"
                  disabled
                  value={settings.memberDetails.messagePrice}
                />
              </div>
            )}
          </div>
          <CommonButton
            onClick={() => onSend()}
            variant="secondary"
            className="w-full mt-10"
          >
            Send Message
          </CommonButton>

          <p className="text-white/60 text-center mt-10">
            By clicking on "continue" you agree to Fanxo's Payment{" "}
            <Link to="/terms-and-conditions">Terms, t&c</Link> and{" "}
            <Link to="/privacy-policy">Privacy Policy.</Link>
          </p>
        </S.PaymentModalWrapper>
      </div>
    </Modal>
  );
};

export default ChatPaymentModal;
