import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Post from "../../../Components/Post/Post";
import ProfileHeader from "../../../Components/Profile/ProfileHeader";
import CommonLoader from "../../../Components/CommonLoader";
import SubscriptionModal from "../../../Modals/SubscriptionModal";
import SignUpModal from "../../../Modals/SignupModal";

import { getToken, isCreatorRole } from "../../../Config/common.config";
import { fetchUserByUserName } from "../../../Utils";
import { viewProfilePage } from "../../../Redux/slices/userSlice";
import { RootState } from "../../../Redux/store";
import { UserDetails } from "../../../Config/interface.config";
import { UserPublicProfile } from "./interface";
import * as H from "../../Home/style";

const initialState: UserPublicProfile = {
  fullname: "",
  email: "",
  bio: "",
  role: "",
  isApproved: false,
  isDeleted: false,
  subscribeTo: [],
  createdAt: "",
  updatedAt: "",
  socialLinks: {
    username: "",
    instagram: "",
    youtube: "",
    threads: "",
    others: "",
  },
  username: "",
  image: { name: "", id: "", fileURL: "" },
  backgroundImage: { name: "", id: "", fileURL: "" },
  id: "",
  posts: [],
  roleType: "",
};

const ViewProfile: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { username } = useParams();
  const [profile, setProfile] = useState<UserPublicProfile>(initialState);
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openAuth, setOpenAuth] = useState<boolean>(false);
  const [refreshFeedsWithCreatorList, setRefreshFeedsWithCreatorList] = useState(false);

  useEffect(() => {
    const token = getToken();
    dispatch(viewProfilePage(!!token));
  }, [dispatch]);

  useEffect(() => {
    if (!username) return;
    
    const fetchUser = async () => {
      setLoading(true);
      try {
        const profileData = await fetchUserByUserName(username);
        if (profileData) {
          setProfile(profileData);
        } else {
          setMessage("No such profile...");
        }
      } catch (error: any) {
        console.error("Error fetching user:", error.response?.data || error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [username]);

  useEffect(() => {
    if (refreshFeedsWithCreatorList) {
      navigate(`/home`);
    }
  }, [refreshFeedsWithCreatorList, navigate]);

  const onSubscribe = (creator: Partial<UserDetails>) => {
    if (creator?.id) {
      setOpen(true);
    }
  };

  const onAuthUser = () => {
    setOpenAuth(true);
  };

  if (loading) {
    return (
      <H.HomeWrapper className="mx-auto">
        <div className="w-full">
          <CommonLoader message="Fetching creator details..." />
        </div>
      </H.HomeWrapper>
    );
  }

  return (
    <H.HomeWrapper>
      <H.Feed className="feed">
        {message && (
          <div className="my-20">
            <p className="text-xl text-white text-center">{message}</p>
          </div>
        )}

        <H.FeedWrapper stretch={isCreatorRole(profile.role)}>
          <div>
            {isCreatorRole(profile.role) && (
              <ProfileHeader
                isProfile={true}
                user={{
                  loading,
                  totalPost: profile.posts.length,
                  backgroundImage: profile.backgroundImage,
                  image: profile.image,
                  bio: profile.bio,
                  username: profile.username,
                  fullname: profile.fullname,
                  id: profile.id,
                  role: profile.role,
                  memberDetails: profile.memberDetails,
                }}
                subscribeCreator={onSubscribe}
                authenticateUser={onAuthUser}
              />
            )}

            <div className="feed-box">
              {profile.posts?.map((post, index) => (
                <Post
                  key={`post-key-${index}`}
                  post={post}
                  locked={!getToken() && post.isLocked}
                />
              ))}
            </div>
          </div>
        </H.FeedWrapper>
      </H.Feed>
      
      <SubscriptionModal
        selectedCreator={profile}
        onHide={() => setOpen(false)}
        show={open}
        setRefreshFeedsWithCreatorList={setRefreshFeedsWithCreatorList}
      />

      <SignUpModal onHide={() => setOpenAuth(false)} show={openAuth} />
    </H.HomeWrapper>
  );
};

export default ViewProfile;