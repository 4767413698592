import { createSlice } from "@reduxjs/toolkit";

// Load initial authentication state from localStorage
const storedAuthState = localStorage.getItem("isAuthenticated") === "true";

interface AuthState {
  isAuthenticated: boolean;
}

const initialState: AuthState = {
  //isAuthenticated: false, // Default to false, update on login
  isAuthenticated: storedAuthState, // Load persisted state
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state) => {
      state.isAuthenticated = true;
      localStorage.setItem("isAuthenticated", "true");
    },
    logout: (state) => {
      state.isAuthenticated = false;
      localStorage.removeItem("isAuthenticated");
      localStorage.clear();
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
