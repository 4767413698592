import React, { useEffect, useState } from "react";
import * as P from "./style";
import { CiHeart } from "react-icons/ci";
import { AiFillHeart } from "react-icons/ai";
import { FeedPosts } from "../../Config/interface.config";
import Comments from "../Comments";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Redux/store";
import { selectedPostForComment } from "../../Redux/slices/commentSlice";
import toast from "react-hot-toast";
import { postAndCommentLinkHandler } from "../../Config/common.config";
import { ReactComponent as CommentIcon } from './Comment.svg';

interface Props {
  post: FeedPosts;
}

const PostFooter: React.FC<Props> = ({ post }) => {
  const [open, setOpen] = useState(false);
  const [liked, setLiked] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state.user);
  const dispatch: AppDispatch = useDispatch();
  const [count, setCount] = useState<{ likes: number, comments: number }>({
    likes: 0,
    comments: 0
  })

  const commentHandler = () => {
    if (!post?._id || !Array.isArray(post?.comments))
      return toast.error("unable to fetch post details.");
    dispatch(
      selectedPostForComment({ post_id: post?._id, comments: post?.comments })
    );
    setOpen(true);
  };

  const postLikeHandler = async () => {
    if (!post?._id || typeof user?.id !== "string") return;

    if (liked === false) {
      setCount({ ...count, likes: count.likes + 1 })
    } else {
      setCount({ ...count, likes: count.likes - 1 })

    }
    setLiked(!liked);

    // const resp: LikePostResponse = 
    await postAndCommentLinkHandler({
      post: post?._id,
      likedBy: user.id,
    });
    // dispatch(updatePostLike(resp));
  };

  useEffect(() => {
    setCount({
      likes: post?.likeCount?.length,
      comments: post?.comments?.length
    })

    if (!post?.likeCount?.length) return;

    const likedByMe = post?.likeCount.find(
      (like) => like.likedBy?._id === user?.id
    );

    if (!likedByMe) return;

    setLiked(true);
  }, [post]);

  return (
    <P.PostFooter>
      <P.ActionWrapper className="relative z-10">
        <div onClick={postLikeHandler}>
          <div className="flex items-end">
            {liked ? (
              <AiFillHeart strokeWidth={0.1} className="text-red-500" size={36} />
            ) : (
              <CiHeart
                strokeWidth={0.1}
                className="text-white"
                size={40}
              />
            )}
            <span className="text-white text-[16px] font-medium">{count.likes}</span>
          </div>
        </div>
        <div className="cursor-pointer ml-1" onClick={commentHandler} id='post-comment-icon'>
          <div className="flex items-end gap-1.5">
            <CommentIcon height={40} fill="#ffffff" />
            <span className="text-white text-[16px] font-medium">{count.comments}</span>
          </div>
        </div>
      </P.ActionWrapper>

      <Comments open={open} setOpen={setOpen} />
    </P.PostFooter>
  );
};

export default PostFooter;
