import React, { useEffect, useState } from "react";
import * as C from "./styled";
import ChatUser from "../../Components/Chat/ChatUser";
import { IoMdMenu } from "react-icons/io";
import Messages from "../../Components/Chat/Messages";
import { MdVerified } from "react-icons/md";
import { BsEmojiSmile } from "react-icons/bs";
import { GoPaperAirplane } from "react-icons/go";
import { FaAngleDown } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import { getUserDetails } from "../../Redux/slices/userSlice";
import { AppDispatch, RootState } from "../../Redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  appendNewMessage,
  fetchMessages,
  fetchSubscribers,
  togglePaymentModal,
} from "../../Redux/slices/messageSlice";
import {
  FANXOBILLPHONENUMBER,
  isCreatorRole,
  loadScript,
} from "../../Config/common.config";
import { io, Socket } from "socket.io-client";
import { Message } from "../../Config/interface.config";
import CommonLoader from "../../Components/CommonLoader";
import ChatPaymentModal from "./ChatPaymentModal";
import APIRequest from "../../API";
import * as PATH from "../../API/path";
import toast from "react-hot-toast";
import { OrderResponse } from "./chat.interface";
import { fetchMemberShipDetails } from "../../Redux/slices/settingSlice";
import userImg from "../../Assets/Images/user-profile-img.png";

let socket: Socket;
const Chat: React.FC = () => {
  const [showUserList, setShowUserList] = useState<boolean>(false);
  const [newMessage, setNewMessage] = useState<string>("");
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const { subscribers, loading, activeChat } = useSelector(
    (state: RootState) => state.message
  );
  const socketURL =
    process.env.REACT_SOCKET_ENDPOINT || "https://api.fanxo.club";
  const selectedSubscriber = subscribers.find(
    (subs) => subs._id === activeChat
  );

  useEffect(() => {
    dispatch(getUserDetails());
    dispatch(fetchMessages());
  }, []);

  useEffect(() => {
    if (selectedSubscriber && selectedSubscriber.creator)
      dispatch(fetchMemberShipDetails(selectedSubscriber.creator?._id));
  }, [selectedSubscriber]);

  useEffect(() => {
    if (user.id) dispatch(fetchSubscribers(user.id));
  }, [user.id]);

  const sendMessageService = () => {
    const message = {
      text: newMessage,
      from: user.id ?? "",
      to: isCreatorRole(user.role)
        ? selectedSubscriber?.subscriber?._id ?? ""
        : selectedSubscriber?.creator?._id ?? "",
      subscriberId: activeChat,
      createdAt: new Date().toISOString(),
    };

    socket.emit("new-message", { message, roomId: selectedSubscriber?._id });
    dispatch(appendNewMessage({ newmessage: message as any }));
    setNewMessage("");
  };

  const handleNewMessage = (e: any) => {
    e.preventDefault();
    if (!newMessage) return;

    // if not creator open the payment modal first.
    if (!isCreatorRole(user.role)) {
      dispatch(togglePaymentModal());
      return;
    }

    sendMessageService();
  };

  useEffect(() => {
    if (!subscribers.length) return;

    if (socket?.connected) socket.disconnect();

    socket = io(socketURL);

    subscribers.forEach((sub) => {
      socket.emit("join", sub._id);
      socket
        .off("new-message")
        .on("new-message", ({ message }: { message: Message }) => {
          dispatch(appendNewMessage({ newmessage: message }));
        });
    });
  }, [subscribers]);

  const initiatePayment = async ({ data }: OrderResponse) => {
    dispatch(togglePaymentModal());
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const paymentObject = new (window as any).Razorpay({
      key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
      amount: data.amount.toString(),
      currency: "INR",
      name: "Fanxo messages",
      description: "User paid to chat with the connection",
      order_id: data.order_id,
      handler: async function (response: any) {
        sendMessageService();
      },
      prefill: {
        name: "@Fanxo",
        email: "fanxobusiness@gmail.com",
        contact: FANXOBILLPHONENUMBER,
      },
      // notes: {
      //   address: "Ahmedabad, Gujarat",
      // },
      theme: {
        color: "#001676",
      },
    });

    paymentObject.open();
  };

  const handleCreateNewOrder = async () => {
    try {
      const promise = APIRequest(PATH.createOrder, {
        creatorId: selectedSubscriber?.creator?._id,
        orderType: "message",
        message: newMessage,
      });

      toast.promise(promise, {
        loading: "Processing order",
        error: () => {
          return "Error in initiating payment, please try again later";
        },
        success: ({ data }) => {
          initiatePayment(data);
          return "Order placed successfully";
        },
      });
    } catch (error) {}
  };

  return (
    <C.MessageWrapper>
      <ChatPaymentModal
        onSend={handleCreateNewOrder}
        messageCharge={user?.memberDetails?.messagePrice}
      />
      <C.MessageShader
        isVisible={showUserList}
        onClick={() => setShowUserList(!showUserList)}
      />
      <C.MessageHeader>
        <C.MessageHeading className="text-white font-bold text-3xl">
          <IoMdMenu
            onClick={() => setShowUserList(!showUserList)}
            className="menu-icon"
          />
          <span>Messages</span>
        </C.MessageHeading>
        {/* <C.NewMessageButton variant="primary">
          <TbPlus />
          <span>New Message</span>
        </C.NewMessageButton> */}
      </C.MessageHeader>

      <C.MessageContainer>
        <C.UserListWrallper showUserList={showUserList}>
          <C.UserListTools>
            <p>All Messages</p>
            <div className="flex gap-2">
              <FaAngleDown size={20} />
              <IoSearch size={20} />
            </div>
          </C.UserListTools>
          {loading && <CommonLoader message="Fetching users..." />}

          {subscribers.map((subscriber, index) => (
            <ChatUser
              subscriber={subscriber}
              userId={user.id ?? ""}
              key={`user-${user.id}`}
            />
          ))}
        </C.UserListWrallper>

        {selectedSubscriber && (
          <C.MessageSection>
            <C.SeletedChatUser>
              <C.UserImage
                alt="user"
                crossOrigin="anonymous"
                src={
                  isCreatorRole(user.role)
                    ? selectedSubscriber?.subscriber?.image?.fileURL || userImg // For creator, use subscriber's image or fallback to userImg
                    : selectedSubscriber?.creator?.image?.fileURL || userImg // For non-creator, use creator's image or fallback to userImg
                }
              />
              {isCreatorRole(user.role)
                ? selectedSubscriber.subscriber.fullname
                : selectedSubscriber.creator.fullname}{" "}
              <span>{isCreatorRole(user.role) && <MdVerified />}</span>
            </C.SeletedChatUser>
            {user.id && activeChat && (
              <C.MessagesWrapper id="message-container">
                <Messages
                  key={"main-messages"}
                  activeChat={activeChat}
                />
              </C.MessagesWrapper>
            )}

            <C.MessageActionWrapper onSubmit={handleNewMessage}>
              <C.MessageActionBtn
                type="button"
                color="#ffffff"
                bgcolor="transparent"
              >
                <BsEmojiSmile
                  size={26}
                  color="#8d8cac"
                  className="button-icon"
                />
              </C.MessageActionBtn>
              <C.NewMessage
                type="text"
                value={newMessage}
                placeholder="Write a message..."
                onChange={(e) => setNewMessage(e.target.value)}
              />
              <C.MessageBtnWrapper>
                <C.MessageActionBtn
                  type="submit"
                  color="#ffffff"
                  bgcolor="transparent"
                >
                  <GoPaperAirplane
                    size={24}
                    color="#8d8cac"
                    className="button-icon"
                  />
                </C.MessageActionBtn>
              </C.MessageBtnWrapper>
            </C.MessageActionWrapper>
          </C.MessageSection>
        )}
      </C.MessageContainer>
    </C.MessageWrapper>
  );
};

export default Chat;
