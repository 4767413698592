import React, { useState } from "react";
import { Modal } from "antd";
import * as M from "./styled";
import * as S from "../Pages/SignUp/styles";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../Redux/store";
import toast from "react-hot-toast";
import { Formik, Form } from "formik";
import * as C from "../common-styles";
import { Link } from "react-router-dom";
import { TbEyeClosed } from "react-icons/tb"; // Assuming this is the correct import for the icon
import ColoredLogo from "../Assets/Images/logo-colored.svg";
import GoogleIcon from "../Assets/Images/google-icon.svg";
import * as validation from "../Config/validation.config";
import * as config from "../Config/common.config";
import * as APIPATHS from "../API/path";
import APIRequest from "../API";
import { AiOutlineEye } from "react-icons/ai";
import * as interFace from "../Config/interface.config";
import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import { FaChevronRight } from "react-icons/fa";
import * as L from "../Pages/Login/styles";
import { updateUser } from "../Redux/slices/userSlice";
import { login } from "../Redux/slices/authSlice";

interface Props {
  onHide: () => void;
  show: boolean;
}

const SignUpModal: React.FC<Props> = ({ onHide, show }) => {
  const dispatch: AppDispatch = useDispatch();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showLogin, setShowLogin] = useState(false);
  const [formData] = useState<interFace.SignUpInterface>({
    email: "",
    fullname: "",
    password: "",
    role: "",
  });
  const [loginformData] = useState<interFace.LoginInterface>({
    email: "",
    password: "",
  });

  const handlerUserAfterLogin = (
    user: interFace.UserDetails,
    data: any,
    message: string
  ) => {
    const { access, refresh }: interFace.AuthData = data.data.tokens;
    config.updateToken(access.token, refresh.token);
    dispatch(updateUser(user));
    dispatch(login());

    onHide();

    return message;
  };

  const onSignUp = async (values: interFace.SignUpInterface) => {
    // Your submission logic goes here
    values.role = process.env.REACT_APP_USER_ROLE_ID;
    const promise = APIRequest(APIPATHS.register, values);

    // creating new user
    toast.promise(promise, {
      loading: "Signin you up...",
      success: (data: any) => {
        if (data.error) throw new Error(data.error.message);
        const user: interFace.UserDetails = data.data.user;
        return handlerUserAfterLogin(
          user,
          data,
          "Account created successfully."
        );
      },
      error: (err) => {
        return err.message;
      },
    });
  };

  const onLogin = async (values: interFace.LoginInterface) => {
    const promise = APIRequest(APIPATHS.login, values);

    toast.promise(promise, {
      loading: "Signing in...",
      success: (data: any) => {
        if (data.error) throw new Error(data.error.message);
        const user: interFace.UserDetails = data.data.user;
        return handlerUserAfterLogin(
          user,
          data,
          `Welcome back ${user.fullname} !`
        );
      },
      error: (err) => {
        return err?.message || "";
      },
    });
  };

  const registerWithGoogle = (credentialResponse: any) => {
    const { email, family_name, given_name }: any =
      credentialResponse.data.user;
    const payload: interFace.SignUpInterface = {
      email,
      fullname: given_name + " " + family_name,
      password: config.generatePassword(12),
      role: process.env.REACT_APP_USER_ROLE_ID,
    };
    onSignUp(payload);
  };

  const authenticateLoginUsingGoogle = useGoogleLogin({
    onSuccess: async ({ access_token }: TokenResponse) => {
      const promise = APIRequest(APIPATHS.authGooglelogin, {
        token: access_token,
      });
      // creating new user
      toast.promise(promise, {
        loading: "Loggin in...",
        success: (data: any) => {
          if (data.error) throw new Error(data.error.message);

          const user: interFace.UserDetails = data.data.user;
          return handlerUserAfterLogin(
            user,
            data,
            `Welcome back ${user.fullname} !`
          );
        },
        error: (err) => {
          return err?.message || "";
        },
      });
    },
  });

  const authenticateUsingGoogle = useGoogleLogin({
    onSuccess: async ({ access_token }: TokenResponse) => {
      const promise = APIRequest(APIPATHS.authWithGoogle, {
        token: access_token,
      });
      // auth user with google
      toast.promise(promise, {
        loading: "Authenticating...",
        success: (data: any) => {
          if (data.error) {
            toast.error(data.error.message); // Show error message via toast
            return "Failed to authenticate!"; // Return a failure message
          }
          registerWithGoogle(data);
          onHide();
          return "Authentication successful!"; // Return a success message
        },
        error: (err: any) => {
          return err.message; // Ensure this returns a string
        },
      });
    },
  });

  const toggleShowPassword = () => setShowPassword((prev) => !prev);

  return (
    <M.DragAndDrop>
      <Modal
        footer={false}
        className="create-post-modal"
        closeIcon={false}
        width={560}
        open={show}
        onCancel={onHide}
        destroyOnClose
      >
        <div className="w-full rounded-[15px] bg-gradient-to-b from-[#146BC3] via-[#B77BC6] to-[#F48E1D] p-0.5 overflow-hidden">
          <div className="flex h-full w-full items-center justify-center bg-black rounded-[15px]  overflow-hidden p-5">
            {!showLogin && (
              <S.SignUpWrapper>
                <S.SignUpContainer>
                  <S.ColoredLogo src={ColoredLogo} />

                  <S.SignUpContent>
                    <h1 className="font-roboto font-reg capitalize">
                      {"Sign Up"}
                    </h1>
                    <Formik
                      initialValues={formData}
                      onSubmit={onSignUp}
                      validationSchema={validation.signUpValidation}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        isSubmitting,
                        resetForm,
                      }) => (
                        <Form>
                          <div className="login-form">
                            <div>
                              <C.CommonInput
                                placeholder="Full name"
                                name="fullname"
                                value={values.fullname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <p className="text-white mt-0.5">
                                {errors.fullname &&
                                  touched.fullname &&
                                  errors.fullname}
                              </p>
                            </div>
                            <div>
                              <C.CommonInput
                                placeholder="Email"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <p className="text-white mt-0.5">
                                {errors.email && touched.email && errors.email}
                              </p>
                            </div>
                            <div>
                              <C.IconInputWrapper>
                                <C.CommonInput
                                  placeholder="Password"
                                  name="password"
                                  type={!showPassword ? "password" : "text"}
                                  value={values.password}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {showPassword ? (
                                  <AiOutlineEye
                                    onClick={toggleShowPassword}
                                    className="input-icon"
                                    size={18}
                                  />
                                ) : (
                                  <TbEyeClosed
                                    onClick={toggleShowPassword}
                                    className="input-icon"
                                    size={18}
                                  />
                                )}
                              </C.IconInputWrapper>
                              <p className="text-white mt-0.5">
                                {errors.password &&
                                  touched.password &&
                                  errors.password}
                              </p>
                            </div>

                            <C.CommonButton
                              variant="primary"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              Continue
                            </C.CommonButton>

                            <p className="text-white text-center">
                              By signing up you agree to our terms and{" "}
                              <Link
                                to="/privacy-policy"
                                className="underline-offset-2 underline"
                              >
                                privacy policy
                              </Link>
                            </p>

                            <div className="or-title">
                              <span>Or</span>
                            </div>

                            <div className="relative">
                              <C.CommonIonButton
                                onClick={() => authenticateUsingGoogle()}
                                type="button"
                                className="border border-[#BABABA]"
                              >
                                <img
                                  alt="fanxo-logo"
                                  width={20}
                                  src={GoogleIcon}
                                />
                                <span>Signup With Google</span>
                              </C.CommonIonButton>

                              <div className="mt-5 cursor-pointer">
                                <div
                                  onClick={() => setShowLogin(true)}
                                  className="text-center text-white flex items-center justify-center gap-3"
                                >
                                  <span>Log in</span>
                                  <FaChevronRight size={18} color="white" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </S.SignUpContent>
                </S.SignUpContainer>
              </S.SignUpWrapper>
            )}
            {showLogin && (
              <L.LoginWrapper>
                <L.LoginContainer>
                  <L.ColoredLogo src={ColoredLogo} />
                  <L.LoginContent>
                    <div className="text-center">
                      <h1 className="font-roboto font-reg">Login</h1>
                    </div>
                    <L.FormContainer className="thisisloginscreen">
                      <Formik
                        initialValues={loginformData}
                        onSubmit={onLogin}
                        validationSchema={validation.LoginpValidation}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          isSubmitting,
                          resetForm,
                        }) => (
                          <Form>
                            <div className="login-form">
                              <div>
                                <C.CommonInput
                                  placeholder="Email"
                                  name="email"
                                  value={values.email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <p className="text-white mt-0.5">
                                  {errors.email &&
                                    touched.email &&
                                    errors.email}
                                </p>
                              </div>
                              <div>
                                <C.IconInputWrapper>
                                  <C.CommonInput
                                    placeholder="Password"
                                    name="password"
                                    type={!showPassword ? "password" : "text"}
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {showPassword ? (
                                    <AiOutlineEye
                                      onClick={toggleShowPassword}
                                      className="input-icon"
                                      size={18}
                                    />
                                  ) : (
                                    <TbEyeClosed
                                      onClick={toggleShowPassword}
                                      className="input-icon"
                                      size={18}
                                    />
                                  )}
                                </C.IconInputWrapper>
                                <p className="text-white mt-0.5">
                                  {errors.password &&
                                    touched.password &&
                                    errors.password}
                                </p>
                              </div>
                              <C.CommonButton
                                variant="primary"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                Continue
                              </C.CommonButton>
                              <div className="or-title">
                                <span>Or</span>
                              </div>
                              <div className="relative">
                                <C.CommonIonButton
                                  className="border border-[#BABABA]"
                                  onClick={() => authenticateLoginUsingGoogle()}
                                  type="button"
                                >
                                  <img alt="fanxo-logo" src={GoogleIcon} />
                                  <span>Login with google</span>
                                </C.CommonIonButton>
                              </div>
                              <div>
                                <div className="mt-5 cursor-pointer">
                                  <div
                                    onClick={() => setShowLogin(false)}
                                    className="text-center text-white flex items-center justify-center gap-3"
                                  >
                                    <span>Sign up as fan</span>
                                    <FaChevronRight size={18} color="white" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </L.FormContainer>
                  </L.LoginContent>
                </L.LoginContainer>
              </L.LoginWrapper>
            )}
          </div>
        </div>
      </Modal>
    </M.DragAndDrop>
  );
};

export default SignUpModal;
