import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import * as S from "./styles";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { ColoredBtn } from "../../common-styles";

const NotFound: React.FC = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  return (
    <S.Notfound>
      <motion.h1
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-6xl font-bold text-white"
      >
        404
      </motion.h1>
      <motion.p
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="text-lg text-gray-600 mt-4"
      >
        Oops! The page you're looking for doesn't exist.
      </motion.p>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
        className="mt-6"
      >
        <Link to={isAuthenticated ? "/home" : "/"}>
          <ColoredBtn className="!px-14 text-white">Return Home</ColoredBtn>
        </Link>
      </motion.div>
    </S.Notfound>
  );
};

export default NotFound;
