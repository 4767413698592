import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import * as R from "./styles";
import NotFound from "../Pages/NotFound";
import Sidebar from "../Components/Sidebar";
import Login from "../Pages/Login";
import SignUp from "../Pages/SignUp";
import Landing from "../Pages/Landing";
import CreatorDetails from "../Pages/CreatorDetails";
import Thankyou from "../Pages/Thankyou";
import ResetPassword from "../Pages/ResetPassword";
import ForgotPassword from "../Pages/ForgotPassword";
import { useSelector } from "react-redux";
import { RootState } from "../Redux/store";
import TermsAndCondition from "../Pages/TermsAndCondition";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import AboutUs from "../Pages/AboutUs";
import FAQs from "../Pages/Faqs";

interface RoutesInterface {
  element: React.FC;
  path: string;
  private?: boolean;
  sidebar?: boolean;
  header?: boolean;
}

const PublicRoutes: React.FC = () => {
  const { viewProfilePage } = useSelector((state: RootState) => state.user);
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  const [routes, setRoutes] = useState<RoutesInterface[]>([
    {
      element: Login,
      path: "/login",
      sidebar: false,
    },
    {
      element: Landing,
      path: "/",
      sidebar: false,
    },
    {
      element: SignUp,
      path: "/signup",
      sidebar: false,
    },
    {
      element: SignUp,
      path: "/signup/:role",
      sidebar: false,
    },
    // {
    //   element: ViewProfile,
    //   path: "/profile/:username",
    //   sidebar: false,
    //   private: false,
    // },
    {
      element: CreatorDetails,
      path: "/creator-details",
      sidebar: false,
      private: false,
    },
    {
      element: Thankyou,
      path: "/thankyou-for-applying",
      sidebar: false,
      private: false,
    },
    {
      element: ResetPassword,
      path: "/password/reset/:token",
      sidebar: false,
      private: false,
    },
    {
      element: ForgotPassword,
      path: "/password/forgot",
      sidebar: false,
      private: false,
    },
    {
      element: TermsAndCondition,
      path: "/terms-and-conditions",
      sidebar: false,
      private: false,
    },
    {
      element: PrivacyPolicy,
      path: "/privacy-policy",
      sidebar: false,
      private: false,
    },
    {
      element: AboutUs,
      path: "/about-us",
      sidebar: false,
      private: false,
    },
    {
      element: FAQs,
      path: "/faqs",
      sidebar: false,
      private: false,
    },
    {
      element: NotFound,
      path: "*",
    },
  ]);

  useEffect(() => {
    if (viewProfilePage) {
      const allRoutes = [...routes];
      const index = routes.findIndex((rt) => rt.path === "/profile/:username");
      if (index !== -1) {
        allRoutes[index].sidebar = true;
        setRoutes(allRoutes);
      }
    }
  }, [routes, viewProfilePage]);

  const componentRenderHandler = ({
    element: Element,
    sidebar,
    private: checkAuth,
  }: RoutesInterface) => {
    const PathElement = sidebar ? (
      <R.RoutesWrapper>
        {sidebar && <Sidebar />}
        <R.Content>
          <Element />
        </R.Content>
      </R.RoutesWrapper>
    ) : (
      <Element />
    );
  
    if (checkAuth && !isAuthenticated) return <Navigate to="/login" replace />;
    return PathElement;
  };

  return (
    <Router>
      <Routes>
        {routes.map((route) => (
          <Route
            key={`key-${route.path}`}
            element={<>{componentRenderHandler(route)}</>}
            path={route.path}
          />
        ))}
      </Routes>
    </Router>
  );
};

export default PublicRoutes;
