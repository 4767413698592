import { useCallback, useEffect, useState, useRef } from "react";
import * as S from "./styles";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft, FaPlus } from "react-icons/fa";
import { CommonButton } from "../../common-styles";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Redux/store";
import {
  fetchMemberShipDetails,
  updateMemberShipDetails,
  requestPayout,
  fetchRequestedPayoutDetails,
} from "../../Redux/slices/settingSlice";
import CommonLoader from "../../Components/CommonLoader";
import { formatIndianNumber } from "../../Utils";
import { message } from "antd";

const Payouts = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const settings = useSelector((state: RootState) => state.setting);
  const user = useSelector((state: RootState) => state.user);

  const [upiId, setUpiId] = useState("");
  const [isAvailableUPI, setIsAvailableUPI] = useState(false);
  const [loading, setLoading] = useState(false);
  const fetchedOnce = useRef(false); // Prevents duplicate API calls

  const totalEarnings = Number(user?.totalEarning ?? 0) / 100;
  const requestedPayoutAmount = Number(settings?.requestedPayoutAmount ?? 0);
  const minPayoutAmount = Number(
    process.env.REACT_APP_MIN_AMOUNT_RESERVED_ON_PAYOUT ?? 0
  );

  const isEligibleForPayout = totalEarnings >= minPayoutAmount;

  const getMemberDetails = useCallback(async () => {
    if (!user?.id) return;
    await dispatch(fetchMemberShipDetails(user.id));
    await dispatch(fetchRequestedPayoutDetails());
  }, [dispatch, user?.id]);

  const submitUPIId = async () => {
    if (!upiId.trim()) return;
    await dispatch(updateMemberShipDetails({ upiId }));
    getMemberDetails();
  };

  const requestWithDraw = async () => {
    if (!user?.id || loading) return;

    if (totalEarnings <= 0) {
      message.warning(
        `You need at least ₹${process.env.REACT_APP_MIN_AMOUNT_RESERVED_ON_PAYOUT} to request a payout.`
      );
      return;
    }

    setLoading(true);
    const payload = {
      creator: user.id,
      amount: totalEarnings / 100,
      status: "Requested",
      upiId,
    };

    await dispatch(requestPayout(payload));
    setTimeout(() => {
      setLoading(false);
      getMemberDetails();
    }, 2000);
  };

  useEffect(() => {
    if (!fetchedOnce.current && user?.id) {
      fetchedOnce.current = true;
      getMemberDetails();
    }
  }, [getMemberDetails, user?.id]);

  useEffect(() => {
    if (settings?.memberDetails?.upiId) {
      setUpiId(settings.memberDetails.upiId);
      setIsAvailableUPI(true);
    }
  }, [settings?.memberDetails?.upiId]);

  if (settings.loading)
    return (
      <S.Payouts>
        <h1 className="text-white text-center font-noto font-semibold">
          Payouts
        </h1>
        <CommonLoader message="Fetching payout details..." />
      </S.Payouts>
    );

  return (
    <S.Payouts className="text-white">
      <h1 className="text-white font-noto flex items-center gap-5 md:gap-10">
        <span onClick={() => navigate("/account")}>
          <FaArrowLeft className="cursor-pointer" size={30} />
        </span>
        <span className="font-semibold">Payouts</span>
      </h1>

      <div className="flex items-center justify-center flex-col">
        <p className="text-gray-400 text-2xl md:text-4xl sm:text-lg">
          Current Earnings
        </p>
        <p className="text-white text-2xl md:text-4xl sm:text-lg my-6">
          ₹{" "}
          {totalEarnings > 0
            ? settings.hadRequestedRequest
              ? formatIndianNumber(totalEarnings - requestedPayoutAmount)
              : formatIndianNumber(totalEarnings)
            : "0.00"}
        </p>

        <div className="md:w-[20rem] flex items-center justify-center flex-col">
          {settings.hadRequestedRequest && (
            <p className="my-5 text-gray-400">
              {`Request pending ₹${formatIndianNumber(requestedPayoutAmount)}`}
            </p>
          )}
          <CommonButton
            variant={
              !isAvailableUPI || settings.hadRequestedRequest
                ? "primary"
                : "secondary"
            }
            onClick={requestWithDraw}
            disabled={
              !isAvailableUPI ||
              loading ||
              settings.hadRequestedRequest ||
              !isEligibleForPayout
            }
          >
            {loading ? "Requesting..." : "Request Withdrawal"}
          </CommonButton>
        </div>

        <div className="my-6 md:w-[20rem] flex items-center flex-col">
          <S.CommonInput
            placeholder="Add your UPI ID"
            name="upiId"
            value={upiId}
            onChange={(e) => setUpiId(e.target.value)}
          />
          {upiId && (
            <div
              className="flex items-center underline underline-offset-2 cursor-pointer mt-2"
              onClick={submitUPIId}
            >
              <FaPlus className="cursor-pointer" size={15} />{" "}
              {isAvailableUPI ? "Update" : "Add"}
            </div>
          )}
        </div>

        <div className="text-center text-gray-400 md:w-[30rem]">
          <p>
            You will receive 80-90% of your earnings after taxes and platform
            fees.
          </p>
          <p>
            Most withdrawals are instant, but it may take up to a week to
            process. You will receive details via email.
          </p>
        </div>
      </div>
    </S.Payouts>
  );
};

export default Payouts;
