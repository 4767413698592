import styled from "styled-components";

export const Notfound = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #000;
  padding: 1rem 0.5rem;
  color: white;
`;
