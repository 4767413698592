import * as interFace from "../../Config/interface.config";
import * as C from "./style";

const Card: React.FC<interFace.FamousUsers> = ({
  imageURL,
  name,
  id,
}: interFace.FamousUsers) => {
  return (
    <C.Card className={`famous-user-card card-${id}`}>
      <C.CardImg src={imageURL} alt="" crossOrigin="anonymous" />
      <C.CardContent>
        <h5>{name}</h5>
      </C.CardContent>
    </C.Card>
  );
};

export default Card;
